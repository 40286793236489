import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import React from 'react';
import { Provider } from 'react-redux';
import { getSentryReduxEnhancer } from '../../../../packs/sentry';
import { errorHandler } from '../../../middleware/errorHandler';
import TrustHubReducer from '../store/trusthubSlice';
import GlobalReducer from '../../../store/global/globalSlice';
import moment from 'moment';
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { TrustHubUserRoutes } from './TrustHubUserRoutes';

const sentryEnhancer = getSentryReduxEnhancer();

const rootReducer = (history: History) =>
  combineReducers({
    trustHub: TrustHubReducer,
    router: connectRouter(history),
    global: GlobalReducer, // for <ErrorToast/> needed state.global.errorMessage
  });

const history = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer(history),
  middleware: [
    ...getDefaultMiddleware(),
    errorHandler,
    routerMiddleware(history),
  ],
  enhancers: sentryEnhancer ? [sentryEnhancer] : undefined,
});

// @ts-ignore
moment.locale(window.navigator.userLanguage || window.navigator.language);

export const TrustHub: React.FC = () => {
  // googleAnalyticsInit();
  return (
    <div className="app">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <TrustHubUserRoutes history={history} />
        </ConnectedRouter>
      </Provider>
    </div>
  );
};
