import React from 'react';
import ReactDOM from 'react-dom';
import { sentryInit } from './sentry';
import { TrustHub } from '../src/features/trusthub/user/TrustHub';

sentryInit();

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<TrustHub />, document.getElementById('trusthub-root'));
});
